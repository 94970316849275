import(/* webpackMode: "eager" */ "/vercel/path0/app/components/canvas/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/components/loading.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/components/me/index.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/components/me/name.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/components/me/yuki.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/page.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/page.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\",\"cyrillic\"]}],\"variableName\":\"font\"}");
